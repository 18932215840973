import { TextField } from 'components/Typography/Typography';
import { getValidatedProfileIcon } from 'helpers/getTalentpathIcon';
import getLanguageObject from 'helpers/language';
import React, { useEffect, useState } from 'react';
import { getStringFromCurrentLanguage } from 'store/language/language.actions';
import styled from 'styled-components';
import { capitalize, transformTalentPathKey } from 'utils/talentPaths';
import { AsideHeader, AsideResults, TalentPathImage } from './ResultSummary.components';
import ResultSummaryProps from './ResultSummary.types';
import theme from 'utils/theme';
import moment from 'moment';

const LabelWithResult = styled.div`
    display: grid;
    grid-template-rows: auto 1fr;
    grid-gap: 5px;
`

const ResultSummary: React.FC<ResultSummaryProps> = ({
    result,
    currentLanguage,
    resultChangeResultDetails,
    canEdit,
    currentOrganisation
}) => {
    const lang = getLanguageObject(currentLanguage);
    const [jobRolUpdatedName, setJobRolUpdatedName] = useState<string>(result?.name || "");

    if (!result) return null;

    const onChangeName = () => {
        resultChangeResultDetails(result.id, jobRolUpdatedName)
    };

    const organisationUnit = currentOrganisation?.organisationUnits.find(x => x.id === result.organisationUnitId)
    const organisationUnitName = organisationUnit?.name;
    const unit = organisationUnit?.units.find(y => y.id === result.unitId);
    const unitName = unit?.name

    return (
        <>
            <AsideHeader bgColor={theme.colorCodePicker(result.validatedProfile.color.toLowerCase())}>
                <div><h1 style={{ margin: 0, fontSize: 52, lineHeight: `${54 * 1.5}px`, fontWeight: 'bold' }}>STYR {result.level}</h1></div>
                <LabelWithResult>
                    <strong>
                        {lang.role}
                    </strong>
                    {canEdit ? <TextField type="text" placeholder={result.name} value={jobRolUpdatedName} onChange={(e) => setJobRolUpdatedName(e.target.value)} onBlur={onChangeName} /> :
                        <span>{result.name}</span>}
                </LabelWithResult>
                <LabelWithResult>
                    <strong>{lang.naturalCareerPath}</strong>
                    <span>
                        {getStringFromCurrentLanguage(result.validatedProfile.talentPath.names, currentLanguage)}
                    </span>
                </LabelWithResult>
                <div>
                    {result.validatedProfile.talentPath.key && <>
                        <TalentPathImage src={getValidatedProfileIcon(result.validatedProfile.talentPath.key, result.level)} alt={result.validatedProfile.talentPath.key} />
                    </>}
                </div>
            </AsideHeader>
            <AsideResults>
                <LabelWithResult>
                    <strong>{lang.organisationUnit}</strong>
                    <span>{getStringFromCurrentLanguage(organisationUnitName, currentLanguage)}</span>
                </LabelWithResult>
                <LabelWithResult>
                    <strong>{lang.unit}</strong>
                    <span>{getStringFromCurrentLanguage(unitName, currentLanguage)}</span>
                </LabelWithResult>
                <LabelWithResult>
                    <strong>{lang.status}</strong>
                    <span>
                        {capitalize(result.status)}
                    </span>
                </LabelWithResult>
                <LabelWithResult>
                        <strong>{lang.createdOn}</strong>
                        <span>{moment.utc(result.createdOn).local().format("DD/MM/YYYY")}</span>
                </LabelWithResult>
            </AsideResults>
        </>
    );
};

export default ResultSummary;
