import { LangObject } from 'helpers/language';

export const pl: LangObject = {
    id: '77009e07-2cf6-4e6f-a7a3-d96e2ade6deb',
    styrAllocator: 'STYR Allocator',
    dashboard: 'Dashboard',
    organisation: 'Organisation/Unit',
    startAllocator: 'Start Allocator',
    searchJobsnRoles: 'Search Jobs/roles',
    talentManagementTool: 'Talent Management System',
    elearningStyrModel: 'E-learning Styr model',
    styrUpdates: 'Release-informatie',
    forgotPassword: 'Forgot password?',
    logout: 'Log out',
    edit: 'Edit', //TODO: Translate
    add: 'Add',
    unitEmpty:
        'Unit name can not be empty. Please enter a unit name to continue.',
    unitAlreadyExists:
        'Unit already exists, please enter a different name to continue',
    noUnits: 'No units available',
    editJob: 'Edit job',
    editFilterOptions: 'Edit filter options',
    styrProfile: 'Validated profile',
    viewValidatedProfile: 'View Validated profile',
    viewJobFamily: 'View Job (Family)',
    viewJobMatrix: 'View Job Matrix',
    talentMatrix: 'Talent Matrix',
    theTalentMatrix: 'The Talent Matrix',
    jobMatrix: 'Job Matrix',
    theJobMatrix: 'The Job Matrix',
    jobTalentMatrix: 'Job Matrix  / Talent Matrix',
    jobMatrixArchitectureName: 'Job Matrix Architecture Name',
    nameAlreadyExists:
        'Name already exists, please enter another name to continue',
    pleaseEnterUnit: 'Please enter a job matrix architecture',
    uploadImage: 'Upload image',
    chooseImage: 'Choose image',
    styrLevel: 'STYR level',
    filterTags: 'Tags',
    freeInputfieldColumn: 'Extra column',
    freeInputfield: 'Extra',
    freeInputfield2: 'Extra 2',
    freeInputfield3: 'Extra 3',
    salaryScale: 'Salary',
    naturalCareerPath: 'Talent path',
    styrGroups: 'STYR group',
    styrGroup: 'STYR group',
    problemSolvingCapability: 'Problem Solving Capability',
    expertiseLevel: 'Expertise Level',
    chooseUserRole: 'Choose a user role',
    userRole: 'Rola użytkownika',
    planningHorizon: 'Planning Horizon',
    continuousImprovement: 'Continuous Improvement',
    autonomy: 'Autonomy',
    communicationSkills: 'Communication Skills',
    managementSkill: 'Coordination Skills (optional)',
    management: 'Management',
    educationLevel:
        'Level of expertise<br/>Working and thinking level /<br/>experience (indication)',
    educationLevelValidatedProfile:
        'Working and thinking level / experience (indication)',
    levelOfExpertise: 'Expertise Level',
    planning_horizon: 'Planning horizon',
    continuous_improvement: 'Continuous Improvement',
    communication_skills: 'Communication Skills',
    coordinationSkills: 'Coordination Skills',
    projectManagementSkills: '(Project) management skills',
    education_level: 'Working and thinking level / experience (indication)',
    styr_competencies: 'Styr-TMA Competences',
    extra_competencies: 'Additional Competences',
    question: 'What is the name of the job or role?',
    unitShort: 'Architecture 2',
    jobMatrixArchitecture: 'Job Matrix Architecture',
    deleteWarningOrganisationUnit:
        'Be careful! When this organisation/unit is deleted, all related results will also be deleted',
    deleteWarningUnit:
        'Be careful! When this unit is deleted, all related results will also be deleted',
    deleteWarningUser: 'Are you sure you want to delete this user?',
    organisationUnit: 'Organisation/Unit',
    unit: 'Unit',
    organisationUnitHasNoUnits: (organisationUnitName: string) =>
        `The organisation/unit ${organisationUnitName} has no units`,
    jobAlreadyExist:
        'Job already exists, please enter another name to continue',
    noNameEntered: 'No name entered',
    unitMultiple: 'Unit',
    addNewOrganisationUnit: 'Add to organisation/unit',
    addNewUnit: 'Add to unit',
    inviteNewUser: 'Invite user',
    inviteExistingUser: 'Invite existing user',
    userInvited: 'New user has been invited',
    inviteNewPartner: 'Invite new consultant',
    partnerInvited: 'New consultant has been invited',
    next: 'Next',
    back: 'Back',
    user: 'User',
    users: 'Users',
    manageUsers: 'Manage users',
    close: 'close',
    title: 'Title',
    description: 'Description',
    comment: 'comment',
    placeholder: (item: string) => `Enter ${item} here...`,
    placeholderError: (item: string) => `! Please enter your ${item} !`,
    noResults: 'No results found',
    questionsServiceDeskHome:
        'If you have any questions, please contact the Styr helpdesk.',
    post: 'Post',
    attachment: 'Attachment',
    submit: 'Submit',
    updates: 'Latest updates',
    recentAllocated: 'Most recent allocated',
    date: 'Date',
    retry: 'Retake',
    role: 'Profile',
    functionOrRole: 'Job / role',
    jobRole: 'Profile',
    jobNRole: 'Profiles',
    level: 'Level',
    organisationName: 'Organisation name',
    createDetails: 'Create details',
    createdOn: 'Created on',
    createOrganisation: 'Create Organisation',
    createUser: 'Create new user',
    createNewOrganisation: 'Add new organisation',
    note: 'Extra information',
    save: 'Save',
    saving: 'Saving...',
    saveResults: 'Save results',
    delete: 'Delete',
    deletePlural: 'Delete',
    newName: 'New name',
    name: 'Name',
    firstName: 'First name',
    lastName: 'Last name',
    editPlural: 'Edit',
    confirmation: (action: string, item: string) =>
        `Are you sure you want to ${action} ${item}?`,
    result: 'Result',
    cancel: 'Cancel',
    freeInputLevel: 'Extra Job Matrix column',
    columns: 'Columns',
    compare: 'Compare',
    choose: 'Choose',
    change: 'Change',
    manageOrganisation: 'Manage Organisation',
    manageValuation: 'Manage valuation method',
    manageValuationDescription:
        'Link the classic job grading levels to the STYR levels. \n\nNote! This will leave only the classic job grading levels visible in the Job Profiler module.',
    pleaseSelectAtleastTwo: 'Please selected at least two levels',
    styrDifferencingFactors: 'Styr differentiating factors',
    showTalentpath: 'Show talent path',
    changeStyrLevel: 'Compare STYR levels',
    selectLevelAfterTalentpathChange:
        'Please select a level after changing the talent path',
    backToValidatedProfile: 'Back to profile',
    backToPreview: 'Back to preview',
    changeLevel: 'Change level',
    talentpath: 'Talent Path',
    chooseStyrLevels: 'Choose STYR levels (max 3)',
    selectStyrLevelsToCompare: 'Select the levels you want to compare (max. 3)',
    currentValidatedProfileLevelIs: 'The current STYR level is ',
    youreAboutToChange: " you're about to change this to level ",
    changing: 'Change',
    theStyrLevel: 'the STYR level',
    view: 'View',
    allocatorWarningOrganisation:
        "You're not able to use the Styr Allocator without adding a job matrix architecture to the organisation.",
    viewInformation: 'Add information',
    maxLogoWidthDescription: 'Max. logo width is 1000px',
    maxLogoWidthWarning: 'Image is to wide',
    supportedFileTypes: 'Supported file types',
    goToManagement: 'Go to Management',
    talentpathAdministrative: 'Administrative',
    talentpathAdvisory: 'Advisory',
    talentpathCommercial: 'Commercial',
    talentpathExpert: 'Expert',
    talentpathManagement: 'Management',
    talentpathProjectManagement: '(Project) Management',
    talentpathSupportCoordination: 'Support & Coordination',
    talentpathProcessManagement: 'Process management',
    talentpathOperationalTechnical: 'Operational / Technical',
    manageJobFamily: 'Job Profiler',
    manageJobFamilyNotEnabled:
        'Job (Families) are not available for the current organisation',
    search: 'Search',
    jobFamilyAlreadyExist: 'Job (Family) already exists',
    pleaseEnterFamilyName: 'Please enter family name',
    addJobFamily: 'Add Job (Family)',
    goToSettings: 'Go to settings',
    addNewJob: 'Create new job',
    status: 'Status',
    changeStatusBulk: 'Change status:',
    jobFamily: 'Job (Family)',
    newJobFamily: 'New Job (Family)',
    nameJobFamily: 'Name Job (Family)',
    lastModified: 'Last modified',
    differentiatingFactorsTitle: 'Differentiating factors',
    changeDifferentiatingFactorsTitle: 'Manage Job Profiler',
    differentiatingFactorsDescription:
        'To manage the visibilty of the differentiating factors go to settings and turn them on and off',
    competencies: 'Competences',
    defaultStyrCompetencies: 'Styr-TMA Competences',
    extraCompetencies: 'Additional Competences',
    mainResponsibilities: 'Main responsibilities',
    manage: 'Manage',
    row: 'Row',
    enterTitle: 'Title',
    enterDescription: 'description',
    addExtraCompetence: 'Add competence',
    manageRows: 'Filter rows',
    createNewJob: 'Create new job',
    selectOrganisationUnit: 'Select Organisation/Unit',
    selectUnit: 'Select Unit',
    selectTalentPath: 'Select talent path',
    selectLevel: 'Select STYR level',
    pleaseFillInAllField: 'You forgot to complete the form.',
    accountability: 'Accountability',
    attention_to_detail: 'Attention To Detail',
    business_orientation: 'Business Orientation',
    commercial_power: 'Commercial Power',
    conduct: 'Conduct',
    controlling_progress: 'Controlling Progress',
    cooperation: 'Cooperation',
    creativity: 'Creativity',
    customer_orientation: 'Customer Orientation',
    decisiveness: 'Decisiveness',
    delegating: 'Delegating',
    developing_employees: 'Developing Employees',
    discipline_competence: 'Discipline',
    flexible_behavior: "'Flexible Behavior",
    focus_on_quality: 'Focus on Quality ',
    forming_judgment: 'Forming Judgment',
    identification_with_management: 'Identification With Management',
    independence: 'Independence',
    innovative_power: 'Innovative Power',
    insight: 'Insight',
    leadership_of_groups: 'Leadership of Groups',
    managing: 'Managing',
    need_to_achieve: 'Need to Achieve',
    networking: 'Networking',
    perseverance: 'Perseverance',
    persuasiveness: 'Persuasiveness',
    planning_and_organizing: 'Planning and Organizing',
    result_orientedness: 'Result-Orientedness',
    sociability: 'Sociability',
    social_awareness: 'Social Awareness',
    verbal_expression: 'Verbal Expression',
    vision: 'Vision',
    workmanship: 'Workmanship',
    written_expression: 'Written Expression',
    courage: 'Courage',
    integrity: 'Integrity',
    backToJobFamilies: 'Back to job (families)',
    showAll: 'Show all',
    jobfamilySearchPlaceholder:
        'Search on: Title, organisation, unit, level etc...',
    filterResults: 'Filter profiles',
    password: 'Password',
    accessCode: 'Access code',
    accessCodeDescription:
        'The access code is not a password. The share link in combination with the access code can be shared through an intranet or similar secure channel. Can not contain special characters.',
    accessCodeError: 'Can not contain special characters.',
    usernameOrEmail: 'Username or Email Address',
    usernameEmpty: 'Please fill in your username to continue',
    passwordEmpty: 'Please fill in your password to continue',
    microsoftNotWorking:
        "Account couldn't be verified. Please try again later or use a different account.",
    switchOrganisation: 'Switch Organisations',
    viewOtherOrganisations: 'View other organisations',

    login: 'Log in',
    forgotPasswordTitle: 'Did you forget your password?',
    backToLogin: 'Back to login',
    requestNewPassword: 'Request password',
    tryAgain: 'Try again',
    newPasswordSend:
        ' The email with a link to reset your password has been sent. Did you not receive the email?',
    emailFieldEmpty: 'The email field is empty.',
    resetPasswordTitle: 'Reset your password',
    resetPasswordSuccess: 'Your password has been succesfully reset.',
    newPassword: 'New password',
    duplicateOrganisation: 'Duplicate current organisation',
    deleteOrganisation: 'Delete Organisation',

    // SHARELINKS
    manageShareLinkTitle: 'Organisation share link',
    manageShareLinkDescription: `Follow the following 3 steps to create a shared link to allow employees access to the Job Matrix/Talent Matrix and its profiles. Only profiles that are set to active will be shown in the Job Matrix / Talent Matrix.
    1) Click "create a new link" and select the view you want to show.
    2) Enter an access code to secure the shared link.
    3) Finally, share the link and access code with the intended recipient and ensure that this is kept confidential to ensure the intended access/security.
    `,
    createNewLink: 'Create new link',
    link: 'Link',
    dateAdded: 'Date added',

    questionDeleteShareLink: 'Are you sure you want to delete?',

    createNewShareLinkTitle: 'Create Share Link',
    createNewShareLinkDescription:
        'Set the viewing rights so that employees have access to the relevant organisation/units and STYR levels. You must protect your link with an access code.',

    allOrganisationUnitsTitle: 'All',
    createdBy: 'Classified by',

    headerSearchbarNoResults: 'No results found',
    headerSearchbarPlaceholder: 'Search for a job, family etc...',

    createNewProfile: 'Create new profile',
    pleaseSelectAnAnswer: 'Please select an answer to continue',

    pleaseSelectALevel: 'Please select a level for the new talentpath.',
    showStyrProfile: 'Show Validated profile',

    createUpdate: 'Create update',
    allocatorQuestions: 'Allocator Questions',
    allocatorQuestion: 'Allocator Question',

    errorTitle: 'Something went wrong',
    errorFailedFetchText:
        "We cannot connect to the server. Please contact the <a href='mailto:helpdesk@styr.nl'>helpdesk</a> if this problem persists. ",
    errorEndText: 'Please try again later.',

    tagsHeader: 'Select tags',
    tagsUntagged: 'Untagged',
    tagsFutureState: 'Future state',
    tagsCurrentState: 'Current state',

    multiselectAllItemsAreSelected: 'All items are selected',
    multiselectClearSearch: 'Clear search',
    multiselectClearSelected: 'Clear selected',
    multiselectNoOptions: 'No options',
    multiselectSearch: 'Search',
    multiselectSelectAll: 'Select All',
    multiselectSelectAllFiltered: 'Select All (Filtered)',
    multiselectSelectSomeItems: 'Select...',
    multiselectCreate: 'Create',
    mulitselectSelect: 'Select',

    //Allocator Error Messages
    loadQuestions:
        'Nie można załadować żądanych pytań, spróbuj ponownie później',
    loadValidatedProfiles:
        'Nie można załadować zwalidowanych profili, spróbuj ponownie później',
    loadLevels: 'Nie można załadować poziomów, spróbuj ponownie później',
    loadTalentpaths:
        'Nie można załadować ścieżek talentów, spróbuj ponownie później',
    createQuestion: 'Nie można utworzyć pytania, spróbuj ponownie później',
    updateQuestion: 'Nie można zaktualizować pytania, spróbuj ponownie później',
    deleteQuestion: 'Nie można usunąć pytania, spróbuj ponownie później',
    createAnswer: 'Nie można utworzyć odpowiedzi, spróbuj ponownie później',
    updateAnswer:
        'Nie można zaktualizować odpowiedzi, spróbuj ponownie później',
    deleteAnswer: 'Nie można usunąć odpowiedzi, spróbuj ponownie później',
    createValidatedProfile:
        'Nie można utworzyć zwalidowanego profilu, spróbuj ponownie później',
    updateValidatedProfile:
        'Nie można zaktualizować zwalidowanego profilu, spróbuj ponownie później',
    deleteValidatedProfile:
        'Nie można usunąć zwalidowanego profilu, spróbuj ponownie później',

    //User Error Messages
    updateOrganisationRequest:
        'Nie można pobrać organizacji, spróbuj ponownie później',
    userSharedLinkLogin:
        'Nie można zalogować się za pomocą udostępnionego linku, spróbuj ponownie później',
    updateOrganisationUsers:
        'Nie można pobrać użytkowników organizacji, spróbuj ponownie później',
    updateOrganisationsRequest:
        'Nie można pobrać organizacji, spróbuj ponownie później',
    changeRole: 'Nie można zmienić roli użytkownika, spróbuj ponownie później',
    inviteUser: 'Nie można zaprosić użytkownika, spróbuj ponownie później',
    invitePartner: 'Nie można zaprosić partnera, spróbuj ponownie później',
    deleteUser: 'Nie można usunąć użytkownika, spróbuj ponownie później',
    deleteOrganisationError:
        'Nie można usunąć organizacji, spróbuj ponownie później',
    duplicateOrganisationError:
        'Nie można zduplikować organizacji, spróbuj ponownie później',
    createNewOrganisationError:
        'Nie można utworzyć nowej organizacji, spróbuj ponownie później',
    updateOrganisationValuation:
        'Nie można zaktualizować wyceny organizacji, spróbuj ponownie później',
    updateOrganisationTMALink:
        'Nie można zaktualizować linku TMA organizacji, spróbuj ponownie później',
    updateOrganisationJobFamilies:
        'Nie można zaktualizować rodzin zawodów organizacji, spróbuj ponownie później',
    updateOrganisationName:
        'Nie można zaktualizować nazwy organizacji, spróbuj ponownie później',
    updateResultInFamily:
        'Nie można zaktualizować job w rodzinie, spróbuj ponownie później',
    addOrganisationUnit:
        'Nie można dodać jednostki organizacyjnej, spróbuj ponownie później',
    updateOrganisationUnit:
        'Nie można zaktualizować jednostki organizacyjnej, spróbuj ponownie później',
    updateOrganisationMatrixes:
        'Nie można włączyć lub wyłączyć macierzy organizacyjnych. Spróbuj ponownie później',
    deleteOrganisationUnit:
        'Nie można usunąć jednostki organizacyjnej, spróbuj ponownie później',
    addUnit: 'Nie można dodać jednostki, spróbuj ponownie później',
    deleteUnit: 'Nie można usunąć jednostki, spróbuj ponownie później',
    updateUnit: 'Nie można zaktualizować jednostki, spróbuj ponownie później',
    updateUnitsAndOrganisationUnitsOrder:
        'Nie można zaktualizować kolejności jednostek i jednostek organizacyjnych, spróbuj ponownie później',
    updateLevelNotes:
        'Nie można zaktualizować notatek poziomu, spróbuj ponownie później',
    uploadOrganisationImage:
        'Nie można przesłać obrazu organizacji, spróbuj ponownie później',
    createShareLink:
        'Nie można utworzyć linku udostępniania, spróbuj ponownie później',
    updateShareLink:
        'Nie można zaktualizować linku udostępniania, spróbuj ponownie później',
    deleteShareLink:
        'Nie można usunąć linku udostępniania, spróbuj ponownie później',
    updatePermissions:
        'Nie można zaktualizować uprawnień, spróbuj ponownie później',
    getRole: 'Nie można pobrać roli, spróbuj ponownie później',
    loadAllOrganisationsRequest:
        'Nie można pobrać wszystkich organizacji, spróbuj ponownie później',
    loadSelectedUserRequest:
        'Nie można pobrać wybranego użytkownika, spróbuj ponownie później',
    updateUserRequest:
        'Nie można zaktualizować użytkownika, spróbuj ponownie później',
    loadPagedUserRequest:
        'Nie można pobrać użytkowników, spróbuj ponownie później',

    //Update Error Messages
    updatesCreateUpdate:
        'Nie można utworzyć aktualizacji w tej chwili, spróbuj ponownie później',
    updatesGetUpdates:
        'Nie można pobrać aktualizacji w tej chwili, spróbuj ponownie później',
    updatesChangeUpdate:
        'Nie można zmienić aktualizacji w tej chwili, spróbuj ponownie później',
    updatesDeleteUpdate:
        'Nie można usunąć aktualizacji w tej chwili, spróbuj ponownie później',

    //Results Error Messages
    getResult: 'Nie można pobrać żądanych job, spróbuj ponownie później.',
    getResults: 'Nie można pobrać żądanych jobs, spróbuj ponownie później.',
    postResult: 'Nie można utworzyć job, spróbuj ponownie później.',
    postResultManual: 'Nie można utworzyć job, spróbuj ponownie później.',
    changeStatusBulkError:
        'Nie można zmienić statusu jobs w masowej operacji, spróbuj ponownie później.',
    changeResult: 'Nie można edytować job, spróbuj ponownie później.',
    changeResultDetails:
        'Nie można zmienić szczegółów job, spróbuj ponownie później.',
    deleteResult: 'Nie można usunąć job, spróbuj ponownie później.',
    changeResultValidatedProfile:
        'Nie można zmienić poziomu styr profilu zweryfikowanego, spróbuj ponownie później.',
    updateResultByField:
        'Nie można zaktualizować jobfamily, spróbuj ponownie później.',
    updateResultExtraCompetence:
        'Nie można zaktualizować dodatkowej kompetencji job, spróbuj ponownie później.',
    changeResultExtraCompetenceOrder:
        'Nie można zmienić kolejności dodatkowych kompetencji job, spróbuj ponownie później.',
    saveExtraCompetence:
        'Nie można zapisać dodatkowej kompetencji job, spróbuj ponownie później.',

    //Language Error Messages
    getRemoteLanguages:
        'Nie można pobrać zdalnych języków, spróbuj ponownie później.',

    //JobFamily Error Messages
    createJobfamily:
        'Nie można utworzyć rodziny zawodów w tej chwili, spróbuj ponownie później',
    updateJobfamily:
        'Nie można zaktualizować rodziny zawodów w tej chwili, spróbuj ponownie później',
    deleteJobfamily:
        'Nie można usunąć rodziny zawodów w tej chwili, spróbuj ponownie później',
    updateDifferentiatingFactor:
        'Nie można zaktualizować cechy różnicującej w tej chwili, spróbuj ponownie później',
    updateJobFamilyByField:
        'Nie można zaktualizować rodziny zawodów w tej chwili, spróbuj ponownie później',

    paginationTake: 'Pokaż',
    filterOrganisation: 'Filtruj organizacje',
    organisations: 'Organizacje',
    allOrganisations: 'Wszystkie organizacje',
    userExportFileName: 'EksportUżytkownika',

    dockInformation: 'Informacje o Dock',
    manual: 'Instrukcja (do pobrania w formacie PDF)',
    ictIPS: 'ICT IPS (pobierz PDF)',

    manageMatrixes: 'Zarządzaj macierzami',
    manageMatrixesDescription: '1 matryca musi być zawsze aktywna. Jeżeli obie matryce zostaną wyłączone, automatycznie włączona zostanie 1 matryca.',

    noPermissionHeader: 'Brak uprawnień',
    noPermissionText:
        'Nie masz uprawnień do tej strony. Skontaktuj się z administratorem.',

    elementDisabledHeader: (element: string) =>
        `${element} jest wyłączony`,
    elementDisabledText: (element: string) =>
        `${element} jest wyłączony. Skontaktuj się z administratorem.`,

    //JobFamily Images
    writeDescription: 'Opis',
    createNewVersion: 'Utwórz nową wersję',
    noExistingFiles: 'Brak istniejących plików',
    versionManagement: 'Zarządzanie wersjami',
};
