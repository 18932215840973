import {
    Document, Font,
    Image, Page, PDFViewer, Text, View
} from '@react-pdf/renderer';
import { getValidatedProfileIcon } from 'helpers/getTalentpathIcon';
import useWindowDimensions from 'helpers/hooks/useWindowDimensions';
import { htmlToPDFoptions } from 'helpers/htmlToPdf';
import getLanguageObject from 'helpers/language';
import React, { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { getStringFromCurrentLanguage } from 'store/language/language.actions';
import fontBook from '../../../assets/fonts/Avenir-Book.ttf';
import fontHeavy from '../../../assets/fonts/Avenir-Heavy.ttf';
import fontItalic from '../../../assets/fonts/Avenir-Italic.ttf';
import { PdfResultContainer } from './PdfResult.components';
import PdfResultProps from './PdfResult.types';
import { styles } from './styling';
import moment from 'moment';
import theme from 'utils/theme';
import { API_URL } from 'config';

const PdfResult: React.FC<PdfResultProps> = ({
    results,
    currentLanguage,
    id,
    resultsGetResult,
    currentOrganisation,
}) => {
    const { width, height } = useWindowDimensions();

    Font.register({
        family: 'Avenir',
        fonts: [
            {
                src: fontHeavy,
            },
        ],
    });

    Font.register({
        family: 'Avenir Book',
        fonts: [
            {
                src: fontBook,
            },
        ],
    });

    Font.register({
        family: 'Avenir Italic',
        fonts: [
            {
                src: fontItalic,
            },
        ],
    });

    useEffect(() => {
        resultsGetResult(id);
    }, [resultsGetResult, id]);



    const lang = getLanguageObject(currentLanguage);
    const result = results.find(r => r.id.toString() === id.toString());
    const processHTML = (input: string) => {
        input = decodeHtml(input);
        return input
            .replace('<Br/>', ', ')
            .replace('<br/>', ', ')
            .replace(/<\/?[^>]+(>|$)/g, '')
            .replace(/\t/g, '')
            .replace(/(\r\n|\r)/gm, '\n')
            .replace('&#8217;', `'`)
            .replace('&#8211;', `-`)
            .replace('&#8211;', `-`)
            .replace('&nbsp;', ' ')
    };

    // Decode HTML entities
    const decodeHtml = (html: string) => {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    if (!result || !result.validatedProfile) {
        return null;
    }

    const hasNote = getStringFromCurrentLanguage(result.jobInformations?.extraField, currentLanguage) !== (null || undefined || '');

    const organisationUnit = currentOrganisation?.organisationUnits.find(x => x.id === result.organisationUnitId)
    const organisationUnitName = organisationUnit?.name;
    const unit = organisationUnit?.units.find(y => y.id === result.unitId);
    const unitName = unit?.name

    const competences = result.validatedProfile.competences ?? [];
    const firstHalf = competences.filter((_, index) => index % 2 === 0);
    const secondHalf = competences.filter((_, index) => index % 2 === 1);

    return (
        <PdfResultContainer>
            <PDFViewer width={width} height={height - 10}>
                <Document>
                    <Page orientation="portrait" style={styles.page}>
                        <View style={styles.aside}>
                            <View style={{
                                padding: 16,
                                paddingTop: 32,
                                height: 100,
                                fontSize: 12,
                                fontWeight: 800,
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                flexDirection: 'row',
                                backgroundColor: theme.colorCodePicker(result.validatedProfile.color)
                            }} >
                                <View style={styles.asideHeaderTitle}>
                                    <Text style={styles.styrLevel}>
                                        STYR {result.level}
                                    </Text>
                                </View>
                                <View style={styles.asideHeaderJob}>
                                    <Text style={styles.titles}>
                                        {lang.role}
                                    </Text>
                                    <Text style={styles.answers}>
                                        {result.name}
                                    </Text>
                                </View>
                                <View style={styles.asideHeadePath}>
                                    <Text style={styles.titles}>
                                        {lang.naturalCareerPath}
                                    </Text>
                                    <Text style={styles.answers}>
                                        {getStringFromCurrentLanguage(result.validatedProfile.talentPath.names, currentLanguage)}
                                    </Text>
                                </View>
                                {result.validatedProfile.talentPath.key && <View style={styles.talentPathIcon}>
                                    <Image style={styles.talentPathSVG} src={getValidatedProfileIcon(result.validatedProfile.talentPath.key, result.level)} />
                                </View>}
                            </View>
                            <View style={styles.asideProfile}>
                                <View style={styles.asideHeaderTitle}>
                                    <Text style={styles.titles}>
                                        {lang.organisationUnit}
                                    </Text>
                                    <Text style={styles.answers}>
                                        {getStringFromCurrentLanguage(organisationUnitName, currentLanguage)}
                                    </Text>
                                </View>
                                <View style={styles.asideHeaderJob}>
                                    <Text style={styles.titles}>
                                        {lang.unit}
                                    </Text>
                                    <Text style={styles.answers}>
                                        {getStringFromCurrentLanguage(unitName, currentLanguage)}
                                    </Text>
                                </View>
                                <View style={styles.asideHeadeStatus}>
                                    <Text style={styles.titles}>Status</Text>
                                    <Text style={styles.answers}>
                                        {result.status.charAt(0).toUpperCase() + result.status.slice(1)}
                                    </Text>
                                </View>
                                <View style={styles.asideHeadeCreatedOn}>
                                    <Text style={styles.titles}>{lang.createdOn}</Text>
                                    <Text style={styles.answers}>
                                        {moment.utc(result.createdOn).local().format("DD/MM/YYYY")}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.main}>
                            <View style={styles.results}>
                                <View style={styles.resultsList}>
                                    <View style={styles.resultItem} wrap={false}>
                                        <Text style={styles.titles}>
                                            {lang.styrGroups}
                                        </Text>
                                        <Text style={styles.answers}>
                                            {processHTML(
                                                getStringFromCurrentLanguage(
                                                    result.validatedProfile
                                                        .styrGroup,
                                                    currentLanguage
                                                )
                                            )}
                                        </Text>
                                    </View>
                                    <View style={styles.resultItem} wrap={false}>
                                        <Text style={styles.titles}>
                                            {lang.problemSolvingCapability}
                                        </Text>
                                        <Text style={styles.answers}>
                                            {processHTML(
                                                getStringFromCurrentLanguage(
                                                    result.validatedProfile
                                                        .problemSolvingCapability,
                                                    currentLanguage
                                                )
                                            )}
                                        </Text>
                                    </View>
                                    {
                                        <View style={styles.resultItem} wrap={false}>
                                            <Text style={styles.titles}>
                                                {lang.expertiseLevel}
                                            </Text>
                                            <Text style={styles.answers}>
                                                {processHTML(
                                                    getStringFromCurrentLanguage(
                                                        result.validatedProfile
                                                            .expertiseLevel,
                                                        currentLanguage
                                                    )
                                                )}
                                            </Text>
                                        </View>}
                                    {
                                        <View style={styles.resultItem} wrap={false}>
                                            <Text style={styles.titles}>
                                                {lang.planningHorizon}
                                            </Text>
                                            <Text style={styles.answers}>
                                                {processHTML(
                                                    getStringFromCurrentLanguage(
                                                        result.validatedProfile
                                                            .planningHorizon,
                                                        currentLanguage
                                                    )
                                                )}
                                            </Text>
                                        </View>}
                                </View>
                                <View style={styles.resultsList}>
                                    {
                                        <View style={styles.resultItem} wrap={false}>
                                            <Text style={styles.titles}>
                                                {lang.continuousImprovement}
                                            </Text>
                                            <Text style={styles.answers}>
                                                {processHTML(
                                                    getStringFromCurrentLanguage(
                                                        result.validatedProfile
                                                            .continuousImprovement,
                                                        currentLanguage
                                                    )
                                                )}
                                            </Text>
                                        </View>}
                                    {
                                        <View style={styles.resultItem} wrap={false}>
                                            <Text style={styles.titles}>
                                                {lang.autonomy}
                                            </Text>
                                            <Text style={styles.answers}>
                                                {processHTML(
                                                    getStringFromCurrentLanguage(
                                                        result.validatedProfile.autonomy,
                                                        currentLanguage
                                                    )
                                                )}
                                            </Text>
                                        </View>}
                                    {
                                        <View style={styles.resultItem} wrap={false}>
                                            <Text style={styles.titles}>
                                                {lang.communicationSkills}
                                            </Text>
                                            <Text style={styles.answers}>
                                                {processHTML(
                                                    getStringFromCurrentLanguage(
                                                        result.validatedProfile
                                                            .communicationSkills,
                                                        currentLanguage
                                                    )
                                                )}
                                            </Text>
                                        </View>}
                                    {/* Because this skill is optional, only render it if it is filled.*/}
                                    {Boolean(result.validatedProfile.managementSkills) && getStringFromCurrentLanguage(
                                        result.validatedProfile.managementSkills,
                                        currentLanguage
                                    ) !== "" &&
                                        <View style={styles.resultItem} wrap={false}>
                                            <Text style={styles.titles}>
                                                {lang.managementSkill}
                                            </Text>
                                            <Text style={styles.answers}>
                                                {processHTML(
                                                    getStringFromCurrentLanguage(
                                                        result.validatedProfile
                                                            .managementSkills,
                                                        currentLanguage
                                                    )
                                                )}
                                            </Text>
                                        </View>
                                    }
                                    {
                                    Boolean(result.validatedProfile.projectManagementSkills) && getStringFromCurrentLanguage(
                                        result.validatedProfile.projectManagementSkills,
                                        currentLanguage
                                    ) !== "" &&
                                        <View style={styles.resultItem} wrap={false}>
                                            <Text style={styles.titles}>
                                                {lang.projectManagementSkills}
                                            </Text>
                                            <Text style={styles.answers}>
                                                {processHTML(
                                                    getStringFromCurrentLanguage(
                                                        result.validatedProfile
                                                            .projectManagementSkills,
                                                        currentLanguage
                                                    )
                                                )}
                                            </Text>
                                        </View>
                                    }
                                    {
                                        <View style={styles.resultItem} wrap={false}>
                                            <Text style={styles.titles}>
                                                {lang.expertiseLevel}
                                            </Text>
                                            <Text style={styles.titles}>
                                                {lang.educationLevelValidatedProfile}
                                            </Text>
                                            <Text style={styles.answers} >
                                                {processHTML(
                                                    getStringFromCurrentLanguage(
                                                        result.validatedProfile
                                                            .educationLevel,
                                                        currentLanguage
                                                    )
                                                )}
                                            </Text>
                                        </View>
                                    }
                                </View>
                            </View>
                        </View>
                        <View style={styles.footer} fixed>
                            <View style={styles.companyLogo} >
                                <Image src={currentOrganisation?.imageUrl ? API_URL + currentOrganisation?.imageUrl : require('assets/images/logo.png')} style={styles.companyLogoImage} />
                            </View>
                        </View>
                    </Page>
                    {currentOrganisation?.differentiatingFactors.styrCompetencies &&
                        <Page orientation="portrait" style={styles.page}>
                            <View style={styles.asideSmall}>
                                <Text style={styles.headerText}>
                                    {lang.styr_competencies}
                                </Text>
                            </View>
                            <View style={styles.results}>
                                <View style={styles.resultsListCompetencies}>
                                    {firstHalf.map((competence) =>
                                        <View style={styles.resultItem} wrap={false}>
                                            <Text style={styles.titles}>
                                                {
                                                    //@ts-ignore
                                                    lang[competence.key] || competence.key}
                                            </Text>
                                            <Text style={styles.answers}>
                                                {processHTML(
                                                    getStringFromCurrentLanguage(competence.translations, currentLanguage)
                                                )}
                                            </Text>
                                        </View>
                                    )}
                                </View>
                                <View style={styles.resultsListCompetencies}>
                                    {secondHalf.map((competence) =>
                                        <View style={styles.resultItem} wrap={false}>
                                            <Text style={styles.titles}>
                                                {
                                                    //@ts-ignore
                                                    lang[competence.key] || competence.key}
                                            </Text>
                                            <Text style={styles.answers}>
                                                {processHTML(
                                                    getStringFromCurrentLanguage(competence.translations, currentLanguage)
                                                )}
                                            </Text>
                                        </View>
                                    )}
                                </View>
                            </View>
                            <View style={styles.footer} fixed>
                                <View style={styles.companyLogo} >
                                    <Image src={currentOrganisation?.imageUrl ? API_URL + currentOrganisation?.imageUrl : require('assets/images/logo.png')} style={styles.companyLogoImage} />
                                </View>
                            </View>
                        </Page>
                    }
                    {hasNote &&
                        <Page orientation="portrait" style={styles.pageNotes} >
                            <View style={styles.notes} wrap={true}>
                                {ReactHtmlParser(getStringFromCurrentLanguage(result.jobInformations?.extraField, currentLanguage), htmlToPDFoptions)}
                            </View>
                            <View style={styles.footer} fixed>
                                <View style={styles.companyLogo} >
                                    <Image src={currentOrganisation?.imageUrl ? API_URL + currentOrganisation?.imageUrl : require('assets/images/logo.png')} style={styles.companyLogoImage} />
                                </View>
                            </View>
                        </Page>
                    }
                </Document>
            </PDFViewer>
        </PdfResultContainer>
    );
};

export default PdfResult;
