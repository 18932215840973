import { LangObject } from 'helpers/language';

export const en: LangObject = {
    id: '5f26d3ef-6112-4cd4-8e7e-305f629d6231',
    styrAllocator: 'STYR Allocator',
    dashboard: 'Dashboard',
    organisation: 'Organisation/Unit',
    startAllocator: 'Start Allocator',
    searchJobsnRoles: 'Search Jobs/roles',
    talentManagementTool: 'Talent Management System',
    elearningStyrModel: 'E-learning STYR model',
    styrUpdates: 'Release-informatie',
    forgotPassword: 'Forgot password?',
    logout: 'Log out',
    edit: 'Edit',
    add: 'Add',
    unitEmpty:
        'Unit name can not be empty. Please enter a unit name to continue.',
    unitAlreadyExists:
        'Unit already exists, please enter a different name to continue',
    noUnits: 'No units available',
    editJob: 'Edit job',
    editFilterOptions: 'Edit filter options',
    styrProfile: 'Validated profile',
    viewValidatedProfile: 'View Validated profile',
    viewJobFamily: 'View Job (Family)',
    viewJobMatrix: 'View Job Matrix',
    talentMatrix: 'Talent Matrix',
    theTalentMatrix: 'The Talent Matrix',
    jobMatrix: 'Job Matrix',
    theJobMatrix: 'The Job Matrix',
    jobTalentMatrix: 'Job Matrix  / Talent Matrix',
    jobMatrixArchitectureName: 'Job Matrix Architecture Name',
    nameAlreadyExists:
        'Name already exists, please enter another name to continue',
    pleaseEnterUnit: 'Please enter a job matrix architecture',
    uploadImage: 'Upload image',
    chooseImage: 'Choose image',
    styrLevel: 'STYR level',
    filterTags: 'Tags',
    freeInputfieldColumn: 'Extra column',
    freeInputfield: 'Extra',
    freeInputfield2: 'Extra 2',
    freeInputfield3: 'Extra 3',
    salaryScale: 'Salary',
    naturalCareerPath: 'Talent path',
    styrGroups: 'STYR group',
    styrGroup: 'STYR group',
    problemSolvingCapability: 'Problem Solving Capability',
    expertiseLevel: 'Expertise Level',
    chooseUserRole: 'Choose a user role',
    userRole: 'User role',
    planningHorizon: 'Planning Horizon',
    continuousImprovement: 'Continuous Improvement',
    autonomy: 'Autonomy',
    communicationSkills: 'Communication Skills',
    managementSkill: 'Coordination Skills (optional)',
    management: 'Management',
    educationLevel:
        'Level of expertise<br/>Working and thinking level /<br/>experience (indication)',
    educationLevelValidatedProfile:
        'Working and thinking level / experience (indication)',
    levelOfExpertise: 'Expertise Level',
    planning_horizon: 'Planning horizon',
    continuous_improvement: 'Continuous Improvement',
    communication_skills: 'Communication Skills',
    coordinationSkills: 'Coordination Skills',
    projectManagementSkills: '(Project) management skills',
    education_level: 'Working and thinking level / experience (indication)',
    styr_competencies: 'STYR-TMA Competences',
    extra_competencies: 'Additional Competences',
    question: 'What is the name of the job or role?',
    unitShort: 'Architecture 2',
    jobMatrixArchitecture: 'Job Matrix Architecture',
    deleteWarningOrganisationUnit:
        'Be careful! When this organisation/unit is deleted, all related results will also be deleted',
    deleteWarningUnit:
        'Be careful! When this unit is deleted, all related results will also be deleted',
    deleteWarningUser: 'Are you sure you want to delete this user?',
    organisationUnit: 'Organisation/Unit',
    unit: 'Unit',
    organisationUnitHasNoUnits: (organisationUnitName: string) =>
        `The organisation/unit ${organisationUnitName} has no units`,
    jobAlreadyExist:
        'Job already exists, please enter a different name to continue',
    noNameEntered: 'No name entered',
    unitMultiple: 'Unit',
    addNewOrganisationUnit: 'Add to organisation/unit',
    addNewUnit: 'Add to unit',
    inviteNewUser: 'Invite user',
    inviteExistingUser: 'Invite existing user',
    userInvited: 'New user has been invited',
    inviteNewPartner: 'Invite new consultant',
    partnerInvited: 'New consultant has been invited',
    next: 'Next',
    back: 'Back',
    user: 'User',
    users: 'Users',
    manageUsers: 'Manage users',
    close: 'close',
    title: 'Title',
    description: 'Description',
    comment: 'comment',
    placeholder: (item: string) => `Enter ${item} here...`,
    placeholderError: (item: string) => `! Please enter your ${item} !`,
    noResults: 'No results found',
    questionsServiceDeskHome:
        'If you have any questions, please contact the Styr helpdesk.',
    post: 'Post',
    attachment: 'Attachment',
    submit: 'Submit',
    updates: 'Latest updates',
    recentAllocated: 'Most recent allocated',
    date: 'Date',
    retry: 'Retake',
    role: 'Profile',
    functionOrRole: 'Job / role',
    jobRole: 'Profile',
    jobNRole: 'Profiles',
    level: 'Level',
    organisationName: 'Organisation name',
    createDetails: 'Create details',
    createdOn: 'Created on',
    createOrganisation: 'Create Organisation',
    createUser: 'Create new user',
    createNewOrganisation: 'Add new organisation',
    note: 'Extra information',
    save: 'Save',
    saving: 'Saving...',
    saveResults: 'Save results',
    delete: 'Delete',
    deletePlural: 'Delete',
    newName: 'New name',
    name: 'Name',
    firstName: 'First name',
    lastName: 'Last name',
    editPlural: 'Edit',
    confirmation: (action: string, item: string) =>
        `Are you sure you want to ${action} ${item}?`,
    result: 'Result',
    cancel: 'Cancel',
    freeInputLevel: 'Extra Job Matrix column',
    columns: 'Columns',
    compare: 'Compare',
    choose: 'Choose',
    change: 'Change',
    manageOrganisation: 'Manage Organisation',
    manageValuation: 'Manage valuation method',
    manageValuationDescription:
        'Link the classic job grading levels to the STYR levels. \n\nNote! This will leave only the classic job grading levels visible in the Job Profiler module.',
    pleaseSelectAtleastTwo: 'Please selected at least two levels',
    styrDifferencingFactors: 'STYR differentiating factors',
    showTalentpath: 'Show talent path',
    changeStyrLevel: 'Compare STYR levels',
    selectLevelAfterTalentpathChange:
        'Please select a level after changing the talent path',
    backToValidatedProfile: 'Back to profile',
    backToPreview: 'Back to preview',
    changeLevel: 'Change level',
    talentpath: 'Talent Path',
    chooseStyrLevels: 'Choose STYR levels (max 3)',
    selectStyrLevelsToCompare: 'Select the levels you want to compare (max. 3)',
    currentValidatedProfileLevelIs: 'The current STYR level is ',
    youreAboutToChange: " you're about to change this to level ",
    changing: 'Change',
    theStyrLevel: 'the STYR level',
    view: 'View',
    allocatorWarningOrganisation:
        "You're not able to use the STYR Allocator without adding a job matrix architecture to the organisation.",
    viewInformation: 'Add information',
    maxLogoWidthDescription: 'Max. logo width is 1000px',
    maxLogoWidthWarning: 'Image is to wide',
    supportedFileTypes: 'Supported file types',
    goToManagement: 'Go to Management',
    talentpathAdministrative: 'Administrative',
    talentpathAdvisory: 'Advisory',
    talentpathCommercial: 'Commercial',
    talentpathExpert: 'Expert',
    talentpathManagement: 'Management',
    talentpathProjectManagement: '(Project) Management',
    talentpathSupportCoordination: 'Support & Coordination',
    talentpathProcessManagement: 'Process management',
    talentpathOperationalTechnical: 'Operational / Technical',
    manageJobFamily: 'Job Profiler',
    manageJobFamilyNotEnabled:
        'Job (Families) are not available for the current organisation',
    search: 'Search',
    jobFamilyAlreadyExist: 'Job (Family) already exists',
    pleaseEnterFamilyName: 'Please enter family name',
    addJobFamily: 'Add Job (Family)',
    goToSettings: 'Go to settings',
    addNewJob: 'Create new job',
    status: 'Status',
    changeStatusBulk: 'Change status:',
    jobFamily: 'Job (Family)',
    newJobFamily: 'New Job (Family)',
    nameJobFamily: 'Name Job (Family)',
    lastModified: 'Last modified',
    differentiatingFactorsTitle: 'Differentiating factors',
    changeDifferentiatingFactorsTitle: 'Manage Job Profiler',
    differentiatingFactorsDescription:
        'To manage the visibilty of the differentiating factors go to settings and turn them on and off',
    competencies: 'Competences',
    defaultStyrCompetencies: 'STYR-TMA Competences',
    extraCompetencies: 'Additional Competences',
    mainResponsibilities: 'Main responsibilities',
    manage: 'Manage',
    row: 'Row',
    enterTitle: 'Title',
    enterDescription: 'description',
    addExtraCompetence: 'Add competence',
    manageRows: 'Filter rows',
    createNewJob: 'Create new job',
    selectOrganisationUnit: 'Select Organisation/Unit',
    selectUnit: 'Select Unit',
    selectTalentPath: 'Select talent path',
    selectLevel: 'Select STYR level',
    pleaseFillInAllField: 'You forgot to complete the form.',
    accountability: 'Accountability',
    attention_to_detail: 'Attention To Detail',
    business_orientation: 'Business Orientation',
    commercial_power: 'Commercial Power',
    conduct: 'Conduct',
    controlling_progress: 'Controlling Progress',
    cooperation: 'Cooperation',
    creativity: 'Creativity',
    customer_orientation: 'Customer Orientation',
    decisiveness: 'Decisiveness',
    delegating: 'Delegating',
    developing_employees: 'Developing Employees',
    discipline_competence: 'Discipline',
    flexible_behavior: "'Flexible Behavior",
    focus_on_quality: 'Focus on Quality ',
    forming_judgment: 'Forming Judgment',
    identification_with_management: 'Identification With Management',
    independence: 'Independence',
    innovative_power: 'Innovative Power',
    insight: 'Insight',
    leadership_of_groups: 'Leadership of Groups',
    managing: 'Managing',
    need_to_achieve: 'Need to Achieve',
    networking: 'Networking',
    perseverance: 'Perseverance',
    persuasiveness: 'Persuasiveness',
    planning_and_organizing: 'Planning and Organizing',
    result_orientedness: 'Result-Orientedness',
    sociability: 'Sociability',
    social_awareness: 'Social Awareness',
    verbal_expression: 'Verbal Expression',
    vision: 'Vision',
    workmanship: 'Workmanship',
    written_expression: 'Written Expression',
    courage: 'Courage',
    integrity: 'Integrity',
    backToJobFamilies: 'Back to job (families)',
    showAll: 'Show all',
    jobfamilySearchPlaceholder:
        'Search on: Title, organisation, unit, level etc...',
    filterResults: 'Filter profiles',
    password: 'Password',
    accessCode: 'Access code',
    accessCodeDescription:
        'The access code is not a password. The share link in combination with the access code can be shared through an intranet or similar secure channel. Can not contain special characters.',
    accessCodeError: 'Can not contain special characters.',
    usernameOrEmail: 'Username or Email Address',
    usernameEmpty: 'Please fill in your username to continue',
    passwordEmpty: 'Please fill in your password to continue',
    microsoftNotWorking:
        "Account couldn't be verified. Please try again later or use a different account.",
    switchOrganisation: 'Switch Organisations',
    viewOtherOrganisations: 'View other organisations',

    login: 'Log in',
    forgotPasswordTitle: 'Did you forget your password?',
    backToLogin: 'Back to login',
    requestNewPassword: 'Request password',
    tryAgain: 'Try again',
    newPasswordSend:
        ' The email with a link to reset your password has been sent. Did you not receive the email?',
    emailFieldEmpty: 'The email field is empty.',
    resetPasswordTitle: 'Reset your password',
    resetPasswordSuccess: 'Your password has been succesfully reset.',
    newPassword: 'New password',
    duplicateOrganisation: 'Duplicate current organisation',
    deleteOrganisation: 'Delete Organisation',

    // SHARELINKS
    manageShareLinkTitle: 'Organisation share link',
    manageShareLinkDescription: `Follow the following 3 steps to create a shared link to allow employees access to the Job Matrix/Talent Matrix and its profiles. Only profiles that are set to active will be shown in the Job Matrix / Talent Matrix.
    1) Click "create a new link" and select the view you want to show.
    2) Enter an access code to secure the shared link.
    3) Finally, share the link and access code with the intended recipient and ensure that this is kept confidential to ensure the intended access/security.
    `,
    createNewLink: 'Create new link',
    link: 'Link',
    dateAdded: 'Date added',

    questionDeleteShareLink: 'Are you sure you want to delete?',

    createNewShareLinkTitle: 'Create Share Link',
    createNewShareLinkDescription:
        'Set the viewing rights so that employees have access to the relevant organisation/units and STYR levels. You must protect your link with an access code.',

    allOrganisationUnitsTitle: 'All',
    createdBy: 'Classified by',

    headerSearchbarNoResults: 'No results found',
    headerSearchbarPlaceholder: 'Search for a job, family etc...',

    createNewProfile: 'Create new profile',
    pleaseSelectAnAnswer: 'Please select an answer to continue',

    pleaseSelectALevel: 'Please select a level for the new talentpath.',
    showStyrProfile: 'Show Validated profile',

    createUpdate: 'Create update',
    allocatorQuestions: 'Allocator Questions',
    allocatorQuestion: 'Allocator Question',

    errorTitle: 'Something went wrong',
    errorFailedFetchText:
        "We cannot connect to the server. Please contact the <a href='mailto:helpdesk@styr.nl'>helpdesk</a> if this problem persists. ",
    errorEndText: 'Please try again later.',

    tagsHeader: 'Select tags',
    tagsUntagged: 'Untagged',
    tagsFutureState: 'Future state',
    tagsCurrentState: 'Current state',

    multiselectAllItemsAreSelected: 'All items are selected',
    multiselectClearSearch: 'Clear search',
    multiselectClearSelected: 'Clear selected',
    multiselectNoOptions: 'No options',
    multiselectSearch: 'Search',
    multiselectSelectAll: 'Select All',
    multiselectSelectAllFiltered: 'Select All (Filtered)',
    multiselectSelectSomeItems: 'Select...',
    multiselectCreate: 'Create',
    mulitselectSelect: 'Select',

    //Allocator Error Messages
    loadQuestions:
        'Cannot load the requested questions, please try again later',
    loadValidatedProfiles:
        'Cannot load the validated profiles, please try again later',
    loadLevels: 'Cannot load the levels, please try again later',
    loadTalentpaths: 'Cannot load the talentpaths, please try again later',
    createQuestion: 'Cannot create the question, please try again later',
    updateQuestion: 'Cannot update the question, please try again later',
    deleteQuestion: 'Cannot delete the question, please try again later',
    createAnswer: 'Cannot create the answer, please try again later',
    updateAnswer: 'Cannot update the answer, please try again later',
    deleteAnswer: 'Cannot delete the answer, please try again later',
    createValidatedProfile:
        'Cannot create the validated profile, please try again later',
    updateValidatedProfile:
        'Cannot update the validated profile, please try again later',
    deleteValidatedProfile:
        'Cannot delete the validated profile, please try again later',

    //User Error Messages
    updateOrganisationRequest:
        'Could not get the organisation, please try again later',
    userSharedLinkLogin:
        'Cannot login with the shared link, please try again later',
    updateOrganisationUsers:
        'Cannot get the users of the organisation, please try again later',
    updateOrganisationsRequest:
        'Cannot get the organisations, please try again later',
    changeRole: 'Cannot change the role of the user, please try again later',
    inviteUser: 'Cannot invite the user, please try again later',
    invitePartner: 'Cannot invite the partner, please try again later',
    deleteUser: 'Cannot delete the user, please try again later',
    deleteOrganisationError:
        'Cannot delete the organisation, please try again later',
    duplicateOrganisationError:
        'Cannot duplicate the organisation, please try again later',
    createNewOrganisationError:
        'Cannot create a new organisation, please try again later',
    updateOrganisationValuation:
        'Cannot update the organisation valuation, please try again later',
    updateOrganisationTMALink:
        'Cannot update the TMA link of the organisation, please try again later',
    updateOrganisationJobFamilies:
        'Cannot update the job families of the organisation, please try again later',
    updateOrganisationName:
        'Cannot update the name of the organisation, please try again later',
    updateResultInFamily:
        'Cannot update the job in the family, please try again later',
    addOrganisationUnit:
        'Cannot add the organisation unit, please try again later',
    updateOrganisationUnit:
        'Cannot update the organisation unit, please try again later',
    updateOrganisationMatrixes:
        'Unable to turn organization matrices on or off, please try again later',
    deleteOrganisationUnit:
        'Cannot delete the organisation unit, please try again later',
    addUnit: 'Cannot add the unit, please try again later',
    deleteUnit: 'Cannot delete the unit, please try again later',
    updateUnit: 'Cannot update the unit, please try again later',
    updateUnitsAndOrganisationUnitsOrder:
        'Cannot update the order of the units and organisation units, please try again later',
    updateLevelNotes: 'Cannot update the level notes, please try again later',
    uploadOrganisationImage:
        'Cannot upload the organisation image, please try again later',
    createShareLink: 'Cannot create the share link, please try again later',
    updateShareLink: 'Cannot update the share link, please try again later',
    deleteShareLink: 'Cannot delete the share link, please try again later',
    updatePermissions: 'Cannot update the permissions, please try again later',
    getRole: 'Cannot get the role, please try again later',
    loadAllOrganisationsRequest:
        'Cannot load all organisations, please try again later',
    loadSelectedUserRequest:
        'Cannot load the selected user, please try again later',
    updateUserRequest: 'Cannot update the user, please try again later',
    loadPagedUserRequest: 'Cannot load the paged users, please try again later',

    //Update Error Messages
    updatesCreateUpdate:
        'Cannot create an update at this moment, please try again later',
    updatesGetUpdates:
        'Cannot get updates at this moment, please try again later',
    updatesChangeUpdate:
        'Cannot change the update at this moment, please try again later',
    updatesDeleteUpdate:
        'Cannot delete the update at this moment, please try again later',

    //Results Error Messages
    getResult: 'Cannot fetch the requested job, please try again later.',
    getResults: 'Cannot fetch the requested jobs, please try again later.',
    postResult: 'Cannot create the job, please try again later.',
    postResultManual: 'Could not create the job, please try again later.',
    changeStatusBulkError:
        'Cannot change status of jobs in bulk, please try again later.',
    changeResult: 'Cannot edit the job, please try again later.',
    changeResultDetails:
        'Cannot change the job details, please try again later.',
    deleteResult: 'Cannot delete the job, please try again later.',
    changeResultValidatedProfile:
        'Cannot change the styr level of the validated profile, please try again later.',
    updateResultByField: 'Cannot update the jobfamily, please try again later.',
    updateResultExtraCompetence:
        'Cannot update the extra competence of the job, please try again later.',
    changeResultExtraCompetenceOrder:
        'Cannot change the order of the extra competences of the job, please try again later.',
    saveExtraCompetence:
        'Cannot save the extra competence of the job, please try again later.',

    //Language Error Messages
    getRemoteLanguages:
        'Cannot retrieve the remote languages, please try again later.',

    //JobFamily Error Messages
    createJobfamily:
        'Cannot create a job family at this moment, please try again later',
    updateJobfamily:
        'Cannot update the job family at this moment, please try again later',
    deleteJobfamily:
        'Cannot delete the job family at this moment, please try again later',
    updateDifferentiatingFactor:
        'Cannot update the differentiating factor at this moment, please try again later',
    updateJobFamilyByField:
        'Cannot update the job family at this moment, please try again later',

    paginationTake: 'Take',
    filterOrganisation: 'Filter organisation',
    organisations: 'Organisations',
    allOrganisations: 'All organisations',
    userExportFileName: 'UserExport',

    dockInformation: 'Dock information',
    manual: 'Manual (PDF Download)',
    ictIPS: 'ICT-IPS (PDF Download)',

    manageMatrixes: 'Manage Matrices',
    manageMatrixesDescription:
        '1 matrix must always be active. If both matrixes are off, 1 matrix will be activated automatically.',

    noPermissionHeader: 'No permission',
    noPermissionText:
        'You do not have permission to view this page. Please contact the administrator.',

    elementDisabledHeader: (element: string) =>
        `${element} is disabled`,
    elementDisabledText: (element: string) =>
        `Please contact the administrator to enable the ${element}.`,

    //JobFamily Images
    writeDescription: 'Write a description',
    createNewVersion: 'Create new version',

    noExistingFiles: 'No existing files',
    versionManagement: 'Version management',
};
